<template>
  <div class="mt-4 mx-3 row white-box px-3 py-4">
    <div class="white-box-top"></div>
    <div class="col-md-4 col-lg-2">
      <nav class="left-nav">
        <h4 class="mb-2">
{{ $t("users.users") }}
</h4>
        <div class="items" v-if="roles === null">
          <Skeletor v-for="i in 5" height="30" class="mb-1 btn" :key="i" />
        </div>
        <div class="items" v-else>
          <button
            v-on:click="
              active = 'users';
              selectedRole = null;
            "
            class="btn w-100 text-start"
            :class="
              active === 'users' && selectedRole === null
                ? 'btn-primary'
                : 'btn-simple'
            "
          >
            {{ $t("users.allUser") }}
          </button>
          <button
            class="btn w-100 text-start"
            v-on:click="
              active = 'users';
              selectedRole = role;
            "
            v-for="role in roles"
            :class="
              active === 'users' && selectedRole?.id === role.id
                ? 'btn-primary'
                : 'btn-simple'
            "
            :key="role.id"
          >
            {{ role.name }}
          </button>
        </div>
        <h4 class="my-2">
{{ $t("users.groups") }}
</h4>
        <div class="items" v-if="roles === null">
          <Skeletor v-for="i in 3" height="30" class="mb-1 btn" :key="i" />
        </div>
        <div class="items" v-else>
          <button
            class="btn w-100 text-start"
            v-for="role in roles"
            :key="role.id"
            :class="
              active === 'group' && selectedRole?.id === role.id
                ? 'btn-primary'
                : 'btn-simple'
            "
            v-on:click="editPermission(role)"
          >
            {{ role.name }}
          </button>
          <button class="btn w-100 text-start" v-on:click="newRole">
            {{ $t("users.createNew") }}
          </button>
        </div>
      </nav>
    </div>
    <div class="col-md-8 col-lg-10">
      <div v-if="active === 'users'">
        <h2>
{{ $t("users.usersAndGroups") }}
</h2>
        <h4>
{{ $t("users.users") }}
</h4>
        <one-by-one-table
          v-model:rows="rows"
          :items="
            users.filter(
              (e) => selectedRole === null || selectedRole.id === e.role_id
            )
          "
          type="users"
          v-on:addbtn="newUser"
          :show-edit="true"
          v-on:edit="editUser"
          v-on:delete="deleteUser"
        />
      </div>
      <div v-if="active === 'group'">
        <h2>
          <b>{{ selectedRole.name }}</b>
{{ $t("users.group") }}

        </h2>
        <h4>
{{ $t("users.privilegesAndAccessRights") }}
</h4>
        <div v-if="permissions === null">
          <div v-for="i in 10" :key="i" class="my-2 mx-1 row">
            <Skeletor size="20" class="col-auto me-2" />
            <Skeletor class="col-auto" :width="Math.random() * 50 + 20 + '%'" />
          </div>
        </div>
        <div v-else v-for="permission in permissions" :key="permission.id">
          <!--<h5 v-if="showPermissionCategory(permission.category)">{{permissionCategories[permission.category]}}</h5>-->
          <label class="cstm-check dropdown-item">
            <span class="text-black mx-2 regular-14">{{
              permission.name
            }}</span>
            <input
              type="checkbox"
              :checked="enabledPermissions[permission.id]"
              v-on:change="
                changePermission(permission.id, $event.target.checked)
              "
            />
            <span class="checkmark select mx-2"></span>
          </label>
        </div>
      </div>
    </div>
    <EditUser
      ref="userModal"
      v-model:user="selectedUser"
      v-model:roles="roles"
      v-model:warehouses="warehouses"
      v-on:add="addUser"
    />
    <AddRole ref="roleModal" v-on:add="addRole" />
    <ConfirmModal
      ref="deleteModal"
      :title="$t(`users.deleteUser`)"
      text="<ul><li>You will delete<b> a user</b></li>
<li>You can't undo this action</li></ul><p>Are you sure you want to delete?</p>"
      cancel-button="No, Keep user"
      ok-button="Yes, delete user"
      v-on:success="deleteEvent"
    />
  </div>
</template>

<script>
import OneByOneTable from "../lists/OneByOneTable";
import { defineAsyncComponent } from "vue";
import http from "../../modules/http";
import EditUser from "../modals/EditUser";
import AddRole from "../modals/AddRole";
import { useStore } from "vuex";
import ConfirmModal from "../modals/ConfirmModal";

export default {
  name: "Users",
  components: { ConfirmModal, AddRole, EditUser, OneByOneTable },
  data() {
    return {
      store: useStore(),
      newUserModal: null,
      error: null,
      selectedUser: {},
      active: "users",
      selectedRole: null,
      rows: [
        {
          name: "Avatar",
          key: "avatar_url",
          type: "avatar",
          component: defineAsyncComponent(() =>
            import("../../components/lists/listItems/Avatar")
          ),
          editable: false,
          show: true,
        },
        {
          name: "Name",
          key: "full_name",
          type: "string",
          editable: false,
          show: true,
        },
        {
          name: "Email",
          key: "email",
          type: "string",
          editable: false,
          show: true,
        },
        {
          name: "Rank",
          key: "role_id",
          type: "enum",
          values: [],
          selectKey: "id",
          label: "name",
          skeletor: "rectangle",
          component: defineAsyncComponent(() =>
            import("../../components/lists/listItems/Tag")
          ),
          show: true,
        },
      ],
      deleteUserId: null,
      users: null,
      roles: null,
      enabledPermissions: null,
      permissions: null,
      permissionCategories: [],
      prevPermissionCategory: -1,
    };
  },
  computed: {
    warehouse() {
      return this.store.state.topBar.warehouse;
    },
    warehouses() {
      return this.store.state.warehouses;
    },
  },
  beforeMount() {
    http
      .fetch("/users/roles")
      .then((data) => {
        this.roles = data;
      })
      .catch(() => {
        this.roles = [];
      });
    http
      .fetch("/users/")
      .then((data) => {
        this.users = data;
      })
      .catch(() => {
        this.users = [];
      });
  },
  methods: {
    showPermissionCategory(id) {
      const r = this.prevPermissionCategory !== id;
      this.prevPermissionCategory = id;
      return r;
    },
    newUser() {
      this.$refs.userModal.showModal();
    },
    newRole() {
      this.$refs.roleModal.showModal();
    },
    addUser(data) {
      this.users.unshift(data);
    },
    addRole(data) {
      this.roles.unshift(data);
    },
    deleteUser(data) {
      this.deleteUserId = data.id;
      this.$refs.deleteModal.showModal();
    },
    deleteEvent() {
      http.fetch("/users/delete", { id: this.deleteUserId }, true);
    },
    editUser(id) {
      this.selectedUser = this.users.find((e) => e.id === id);
      this.$refs.userModal.showModal();
      console.log(this.selectedUser);
    },
    editPermission(role) {
      this.active = "group";
      this.selectedRole = role;
      this.getPermissions();
    },
    getPermissions() {
      http
        .fetch("/users/permissions?role=" + this.selectedRole.id)
        .then((data) => {
          this.enabledPermissions = data.enabled;
          this.permissions = data.permissions;
          this.permissionCategories = data.categories;
        })
        .catch(() => {
          this.enabledPermissions = [];
          this.permissions = [];
        });
    },
    changePermission(id, status) {
      let pr = {};
      pr[id] = status;
      http.fetch(
        "/users/save-permissions",
        {
          permissions: pr,
          role: this.selectedRole.id,
        },
        true
      );
    },
  },
  watch: {
    roles() {
      this.rows[3].values = this.roles;
    },
  },
};
</script>
