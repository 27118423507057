<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t("editUser.inviteNewUser") }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`confirmModal.close`)"
          ></button>
        </div>
        <div class="modal-body">
          <div class="text-start custom-input whitebg-input">
            <div class="row mb-3">
              <div class="col-12">
                <label class="form-label bold-12 text-gray">{{ $t("finance.email") }}</label>
                <input
                  type="email"
                  class="form-control remove-padding"
                  :placeholder="$t(`finance.emailAddress`)"
                  v-model="newUserData.email"
                />
              </div>
            </div>
            <div
              class="col-12"
              v-if="newUserData.id && newUserData.email_verified_at === null"
            >
              <button
                class="btn btn-primary"
                :disabled="loadResend"
                :class="{ 'loading-spin': loadResend }"
                v-on:click="resend"
              >
                <span>{{ $t("editUser.sendInvitationEmailAgain") }}</span>
              </button>
            </div>
            <div class="col-12">
              <label class="form-label bold-12 text-gray">{{ $t("editUser.role") }}</label>
              <label
                class="cstm-check dropdown-item"
                v-for="role in roles"
                :key="role.id"
              >
                <span class="text-black mx-2 regular-14">{{ role.name }}</span>
                <input
                  type="radio"
                  name="role"
                  :value="role.id"
                  v-model="newUserData.role_id"
                />
                <span /><span /><span /><span class="checkmark mx-2"></span>
              </label>
            </div>
            <div class="col-12">
              <label class="form-label bold-12 text-gray">{{ $t("editUser.warehouses") }}</label>
              <label
                class="cstm-check dropdown-item"
                v-for="warehouse in warehouses"
                :key="warehouse.id"
              >
                <span class="text-black mx-2 regular-14">{{
                  warehouse.name
                }}</span>
                <input
                  type="checkbox"
                  name="warehouse"
                  :value="warehouse.id"
                  v-model="newUserData.warehouses"
                />
                <span /><span /><span /><span class="checkmark mx-2"></span>
              </label>
            </div>
          </div>
          <div class="error my-2 text-danger fw-bold" v-if="error">
            <div v-for="e in error" :key="e">{{ e[0] }}</div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            v-on:click="editUser()"
            class="btn btn-primary"
            v-if="newUserData.id"
          >
            {{ $t("editOnClick.edit") }}
          </button>
          <button
            type="button"
            v-on:click="addUser()"
            class="btn btn-primary"
            v-else
          >
            {{ $t("editUser.invite") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "../../modules/http";

const bootstrap = require("bootstrap");
import { useStore } from "vuex";

export default {
  name: "EditUser",
  props: {
    user: Object,
    roles: Array,
    warehouses: Array,
  },
  data() {
    return {
      store: useStore(),
      myModal: undefined,
      loadResend: false,
      newUserData: {
        role_id: null,
        email: "",
        warehouses: [],
      },
      error: null,
    };
  },
  watch: {
    user: {
      deep: true,
      handler() {
        this.newUserData = this.user;
      },
    },
  },
  methods: {
    showModal() {
      if (this.myModal === undefined) {
        this.myModal = new bootstrap.Modal(this.$refs.modal, {
          backdrop: "static",
        });
      }
      this.myModal.show();
    },
    resend() {
      this.loadResend = true;
      http
        .fetch("/users/resend-email", { id: this.newUserData?.id })
        .then(() => {
          this.loadResend = false;
        })
        .catch((err) => {
          this.loadResend = false;
          this.error = [[err.message]];
        });
    },
    editUser() {
      http
          .fetch("/users/edit-user", this.newUserData)
          .then(() => {
            this.myModal.hide();
          })
          .catch((err) => {
            if (err.errors) {
              this.error = err.errors;
            } else {
              this.error = [[err.message]];
            }
          });
    },
    addUser() {
      http
        .fetch("/users/add-user", this.newUserData)
        .then((data) => {
          this.$emit("add", data);
          this.myModal.hide();
        })
        .catch((err) => {
          if (err.errors) {
            this.error = err.errors;
          } else {
            this.error = [[err.message]];
          }
        });
    },
  },
  emits: ["add"],
};
</script>
