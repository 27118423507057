<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t("addRole.addANewRoleGroup") }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`addNewContactsModal.close`)"
          ></button>
        </div>
        <div class="modal-body">
          <div class="text-start custom-input whitebg-input">
            <div class="row mb-3">
              <div class="col-12">
                <label class="form-label bold-12 text-gray">{{ $t("addNewContactsModal.name2") }}</label>
                <input
                  type="text"
                  class="form-control remove-padding"
                  v-model="name"
                />
              </div>
            </div>
          </div>
          <div class="error my-2 text-danger fw-bold" v-if="error">
            <div v-for="e in error" :key="e">{{ e[0] }}</div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            v-on:click="createRole()"
            class="btn btn-primary"
          >
            {{ $t("addRole.create") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "../../modules/http";

const bootstrap = require("bootstrap");
import { useStore } from "vuex";

export default {
  name: "AddRole",
  data() {
    return {
      store: useStore(),
      myModal: undefined,
      name: "",
      error: null,
    };
  },
  methods: {
    showModal() {
      if (this.myModal === undefined) {
        this.myModal = new bootstrap.Modal(this.$refs.modal, {
          backdrop: "static",
        });
      }
      this.myModal.show();
    },
    createRole() {
      http
        .fetch("/users/roles", {name: this.name})
        .then((data) => {
          this.$emit("add", data);
          this.myModal.hide();
        })
        .catch((err) => {
          if (err.errors) {
            this.error = err.errors;
          } else {
            this.error = [[err.message]];
          }
        });
    },
  },
  emits: ["add"],
};
</script>
